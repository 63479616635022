// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC413EkqBXGwLok1X9XVcHbQm3lINK77Ho",
  authDomain: "check-my-chess.firebaseapp.com",
  projectId: "check-my-chess",
  storageBucket: "check-my-chess.appspot.com",
  messagingSenderId: "1002979060265",
  appId: "1:1002979060265:web:fb63b566211c9aa2cfca38",
  measurementId: "G-DC7XVS4E92"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize App Check
window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = !!process.env.REACT_APP_FIREBASE_APP_CHECK_DEBUG;
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdT5-8kAAAAADJnN1aG4iY9nrEqOJ_wsBzAABbH'),
  isTokenAutoRefreshEnabled: false
});

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
// connectFunctionsEmulator(functions, 'localhost', 5001);