import React from "react";
import { CircularProgress, Typography } from "@mui/material";

export default function LoadingSpinner({text}) {
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "calc(70vh)"}}>
      <CircularProgress color="primary" size={120} />
      { text && <Typography variant="h5" color="#128080">{text}</Typography>}
    </div>
  );
}