import React, { useEffect, useState } from "react";
import { 
  Avatar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography 
} from "@mui/material";
import { approveCoachApplication, getPhotoIdUrl, getProfilePicUrl, loadCoachApplication, loadCoachApplications } from "helpers/apiHelpers";
import { formatDateTime } from "helpers/datetimeHelpers";
import LoadingSpinner from "components/LoadingSpinner";
import { Link } from "react-router-dom";
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SearchIcon from '@mui/icons-material/Search';

const PAGE_NEXT = 'page-next';
const PAGE_PREV = 'page-prev';

const DEFAULT_ROWS_PER_PAGE = 10;
const DEFAULT_SORT_PARAMS = {field: "applyDate", direction: "desc"};

export default function ApplicationsTabPanel() {
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [sortParams, setSortParams] = useState(DEFAULT_SORT_PARAMS);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [selectedApplicationId, setSelectedApplicationId] = useState(null);
  const [photoIdUrl, setPhotoIdUrl] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [totalRows, setTotalRows] = useState(0);
  const [visibleRows, setVisibleRows] = useState({});
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [searchParam, setSearchParam] = useState('');

  useEffect(() => {
    loadCoachApplications({ sort: DEFAULT_SORT_PARAMS, page: {size: DEFAULT_ROWS_PER_PAGE}}).then(result => {
      setApplications(result.data || []);
      setTotalRows(result.total);
      setVisibleRows(result.docRefs);
      setLoading(false);
    });
  }, []);

  const fetchCoachApplications = (opts) => {
    if (opts?.rowsPerPage) {
      setRowsPerPage(opts.rowsPerPage);
    }
    const pageParams = { size: opts?.rowsPerPage || rowsPerPage };
    const pagingDirection = opts?.pagingDirection;
    if (pagingDirection) {
      if (pagingDirection === PAGE_NEXT) {
        pageParams.startAfter = visibleRows.last;
      } else {
        pageParams.endBefore = visibleRows.first;
      }
    }
    loadCoachApplications({ sort: sortParams, page: pageParams, search: searchParam}).then(result => {
      setApplications(result?.data || []);
      setTotalRows(result?.total || 0);
      setVisibleRows(result?.docRefs);
      setLoading(false);
      if (result?.data?.length === 1) {
        handleSelectRow(result.data[0].id);
      } else {
        setSelectedApplication(null);
        setSelectedApplicationId(null);
      }
    });
  };

  const fetchCoachApplication = (id) => {
    loadCoachApplication(id).then(application => {
      setSelectedApplication(application);
    });
    getPhotoIdUrl(id).then(url => {
      setPhotoIdUrl(url);
    });
    setProfilePicUrl(getProfilePicUrl(id));
  };

  const handleSort = (field) => {
    setPage(0);
    if (sortParams.field === field) {
      setSortParams({field, direction: sortParams.direction === "asc" ? "desc" : "asc"});
    } else {
      setSortParams({field, direction: "asc"});
    }
    fetchCoachApplications();
  };

  const handleSelectRow = (id) => {
    setPhotoIdUrl(null);
    setProfilePicUrl(null);
    setSelectedApplicationId(id);
    fetchCoachApplication(id);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage === page) {
      return;
    }
    if (newPage > page) {
      fetchCoachApplications({pagingDirection: PAGE_NEXT});
    } else {
      fetchCoachApplications({pageinDireciton: PAGE_PREV});
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage();
    setPage(0);
    fetchCoachApplications({rowsPerPage: parseInt(event.target.value, 10)});
  };

  const openApproveDialog = () => {
    setApproveDialogOpen(true);
  };
  const closeApproveDialog = () => {
    setApproveDialogOpen(false);
  };

  const handleSubmitApproveDialog = (form) => {
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    approveCoachApplication({
      coachId: selectedApplication.id,
      language: selectedApplication.language,
      ...formJson
    }).then(() => closeApproveDialog());
  };

  const handleSearchInputChange = (event) => {
    setSearchParam(event.target.value);
  };

  const handleSearch = () => {
    fetchCoachApplications({search: searchParam});
  };

  const ApplicationsTableHeaderCell = ({label, field}) => {
    return (
      <TableCell sx={{color: '#128080'}}>
        <TableSortLabel 
          active={field === sortParams.field}
          direction={field === sortParams.field ? sortParams.direction : 'asc'}
          onClick={() => handleSort(field)}
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  };

  const ApplicationsTable = () => {
    return (
      <Paper sx={{height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        <TableContainer>
          <Table stickyHeader sx={{minWidth: '480px'}}>
            <TableHead>
              <TableRow>
                <ApplicationsTableHeaderCell label="Applied On" field="applyDate"/>
                <ApplicationsTableHeaderCell label="Name" field="fullName" />
                <ApplicationsTableHeaderCell label="Title" field="title" />
                <ApplicationsTableHeaderCell label="Location" field="country" />
                <ApplicationsTableHeaderCell label="Status" field="status" />
              </TableRow>
            </TableHead>
            <TableBody>
            { applications.map((application) => (
              <TableRow 
                key={application.id} 
                sx={{'&:hover': {cursor: 'pointer', backgroundColor: 'rgba(18, 128, 128, 0.10)'}}} 
                selected={application.id === selectedApplicationId}
                onClick={() => handleSelectRow(application.id)} 
              >
                <TableCell>{formatDateTime(application.applyDate)}</TableCell>
                <TableCell>{application.fullName}</TableCell>
                <TableCell>{application.title}</TableCell>
                <TableCell>
                  <span className={`fi fi-${application.country.code.toLowerCase()}`} style={{marginRight: '4px'}}></span>
                  {`${application.country.label} (${application.country.code})`}
                </TableCell>
                <TableCell>{application.status}</TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE, 25, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    );
  };

  const InfoField = ({label, value}) => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', mt: '8px', alignItems: 'center'}}>
        <Typography variant="body1" color="primary">{label}:&nbsp;</Typography>
        <Typography variant="body1">{value}</Typography>
      </Box>
    );
  };

  const SelectedApplicationInfo = () => {
    const languages = (selectedApplication?.language.name || '') + (selectedApplication?.secondLanguage ? `, ${selectedApplication.secondLanguage.name}` : '');
    return (
      <Card sx={{height: '100%', width: '440px', ml: '16px'}}>
        <Box sx={{p: '16px', pt: '8px', display: 'flex', flexDirection: 'column', overflow: 'auto', height: 'calc(100% - 24px)'}}>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <InfoField label={"Name"} value={selectedApplication?.fullName} />
            {selectedApplication && (
              selectedApplication?.status === "PENDING" ?
                <Button variant="outlined" startIcon={<VerifiedOutlinedIcon htmlColor="#c5c5c5" />} sx={{'.MuiButton-startIcon': {m: 0}}} onClick={() => openApproveDialog()}/>
                :
                <Tooltip 
                  title={formatDateTime(selectedApplication.approvalDate)} 
                  placement="top" 
                  arrow 
                  slotProps={{popper: { modifiers: [{ name: 'offset', options: {offset: [0, -8]} }]}}}
                >
                  <span><Button variant="text" disabled color="primary" startIcon={<VerifiedOutlinedIcon htmlColor="#128080"/>} sx={{'.MuiButton-startIcon': {m: 0}}}/></span>
                </Tooltip>
                
            )}
          </Box>
          {selectedApplication && 
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', mt: '16px'}}>
            {profilePicUrl && 
            <Link target='_blank' to={profilePicUrl}>
              <Avatar src={profilePicUrl} alt="coach avatar" sx={{ width: 96, height: 96, marginBottom: "8px", '&:hover': {cursor: 'pointer'}}}/>
            </Link>}
            {photoIdUrl && 
            <Link target='_blank' to={photoIdUrl}>
              <img src={photoIdUrl} alt="government issued id" style={{height: 96, cursor: 'pointer'}}></img>
            </Link>}
          </Box>}
          <InfoField label={"Email"} value={selectedApplication?.email} />
          <InfoField label={"ID"} value={selectedApplication?.id} />
          <InfoField label={"Apply Date"} value={formatDateTime(selectedApplication?.applyDate || {})} />
          <InfoField label={"Title"} value={selectedApplication?.title} />
          <Box sx={{display: 'flex', flexDirection: 'row', mt: '8px', alignItems: 'center'}}>
            <Typography variant="body1" color="primary">Location:&nbsp;</Typography>
            {selectedApplication && <>
              <span className={`fi fi-${selectedApplication?.country.code.toLowerCase()}`} style={{marginRight: '4px'}}></span>
              <Typography>{`${selectedApplication?.country.label} (${selectedApplication?.country.code})`}</Typography>
            </>}
          </Box>
          <InfoField label={"Language(s)"} value={languages} />
          <InfoField label={"FIDE ID"} value={selectedApplication?.fideId} />
          <Box sx={{display: 'flex', flexDirection: 'row', mt: '8px', alignItems: 'center'}}>
            <Typography variant="body1" color="primary">URL:&nbsp;</Typography>
            <Link to={`/coaches/${selectedApplication?.slug}`} target="_blank" style={{color: '#128080'}} >
              <Typography>{selectedApplication?.slug}</Typography>
            </Link>
          </Box>
          <InfoField label={"lichess.org"} value={selectedApplication?.lichessLink} />
          <InfoField label={"chess.com"} value={selectedApplication?.chesscomLink} />
          <Box sx={{display: 'flex', flexDirection: 'column', mt: '8px'}}>
            <Typography variant="body1" color="primary">About me:</Typography>
            <Typography variant="body1">{selectedApplication?.aboutMe}</Typography>
          </Box>
        </Box>
      </Card>
    );
  };

  const ApproveDialog = () => {
    return (
      <Dialog
        open={approveDialogOpen}
        onClose={() => closeApproveDialog()}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleSubmitApproveDialog(event.currentTarget);
          },
        }}
      >
        <DialogTitle sx={{textAlign: 'center'}}>
          Approve Coach
          <Typography variant="body2">{`${selectedApplication?.fullName} (${selectedApplication?.title})`}</Typography>
        </DialogTitle>
        <DialogContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '240px'}}>
          <TextField fullWidth autoComplete="off" required margin="dense" variant="standard" id="coachNumber" name="coachNumber" label="Coach Number"/>
          <TextField fullWidth autoComplete="off" required margin="dense" variant="standard" id="elo" name="elo" label="ELO"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Link to="https://www.fide.com/search" target="_blank">
                    <SearchIcon color="primary"/>
                  </Link>
                </InputAdornment>
              )
            }}
          />
          <TextField fullWidth autoComplete="off" required margin="dense" variant="standard" id="mockReviewId" name="mockReviewId" label="Mock Review Id"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Link to="/coaches/demo" target="_blank">
                    <AddShoppingCartIcon color="primary"/>
                  </Link>
                </InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeApproveDialog}>Cancel</Button>
          <Button type="submit" variant="contained">Approve</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const getSearchInput = () => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mb: '8px', width: '100%'}}>
        <TextField 
          id="search-input-textfield"
          autoComplete="off"
          variant="outlined"
          placeholder="id, email, slug, or fide-id"
          fullWidth
          InputProps={{startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )}}
          onChange={handleSearchInputChange}
          onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
        />
        <Button variant="outlined" sx={{height: '100%', ml: '8px'}} onClick={handleSearch}>Search</Button>
      </Box>
    );
  };

  return loading ? <LoadingSpinner /> : (
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', p: "16px", height: 'calc(100vh - 360px)', maxWidth: '1420px'}}>
      <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
        { getSearchInput() }
        <ApplicationsTable />
      </Box>
      <SelectedApplicationInfo />
      <ApproveDialog />
    </Box>
  );
}