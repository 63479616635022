import LoadingSpinner from "components/LoadingSpinner";
import { authContext } from "contexts/AuthContext";
import { loadCoach, loadOrder } from "helpers/apiHelpers";
import ErrorPage from "pages/error/ErrorPage";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CoachOrderView from "./CoachOrderView";
import PlayerOrderView from "./PlayerOrderView";

export default function OrderView() {
  const { user, authenticating, coach } = useContext(authContext);
  const pathParams = useParams();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});

  const fetchOrder = async (orderId) => {
    await loadOrder(orderId).then((order) => {
      if (user.isCoach) {
        setOrder({...order, coach});
        setLoading(false);
        if (order.status === 'PROCESSING') {
          setTimeout(() => fetchOrder(orderId), 10000); // fetch again in 10 seconds if "PROCESSING" (setTimeout easier to manage in this case than setInterval)
        }
      } else {
        loadCoach(order.coachId).then((coachFromDb) => {
          setOrder({...order, coach: coachFromDb});
          setLoading(false);
        });
      }
    });
  };

  useEffect(() => {
    if (!authenticating && user?.authenticated && coach && pathParams) {
      fetchOrder(pathParams.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, authenticating, coach, pathParams]);

  const refreshOrder = () => {
    fetchOrder(pathParams.id);
  };

  if (authenticating) {
    return <LoadingSpinner />;
  } else {
    if (user?.authenticated) {
      if (loading) {
        return <LoadingSpinner />;
      }
      return user.isCoach ? <CoachOrderView order={order} refreshOrder={refreshOrder}/> : <PlayerOrderView order={order} />;
    }
    return <ErrorPage errorStatus={401} />
  }
}