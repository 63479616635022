import LoadingSpinner from "components/LoadingSpinner";
import { authContext } from "contexts/AuthContext";
import { getStripeLink } from "helpers/apiHelpers";
import ErrorPage from "pages/error/ErrorPage";
import React, { useContext, useEffect, useState } from "react";

export default function CoachPaymentsPage() {
  const { user, authenticating, coach } = useContext(authContext);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState();

  useEffect(() => {
    if (!authenticating && user?.authenticated) {
      if (coach) {
        setLoadingText('Redirecting to Stripe...');
        getStripeLink(coach.id).then((stripeLink) => {
          window.location.replace(stripeLink.url);
        });
      } else {
        setLoading(false);
      }
    }
  }, [user, authenticating, coach]);

  if (loading) {
    return <LoadingSpinner text={loadingText} />;
  } else if (!user?.authenticated || !user.isCoach) {
    return <ErrorPage errorStatus={404} />;
  }
}