import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material";
import OrderSnapshot from "components/OrderSnapshot";
import { formatDateTime } from "helpers/datetimeHelpers";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { useNavigate } from "react-router-dom";

export default function PlayerOrderList({ orders }) {
  const navigate = useNavigate();
  
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: '16px'}}>
      { orders.map((order) => {
        return (
          <Accordion key={order.id} elevation={3}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`order-panel-conent-${order.id}`}
              id={`order-panel-header-${order.id}`}
            >
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                  <Typography>{(order.status === 'COMPLETED' && order.completedDate) ? formatDateTime(order.completedDate) : '(In Progress)'}</Typography>  
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Button 
                  variant={order.status === 'COMPLETED' ? "contained" : "outlined"}
                  disabled={order.status !== 'COMPLETED'} 
                  startIcon={<OndemandVideoIcon />}
                  onClick={() => navigate(`/orders/${order.id}`)}
                >
                  { order.status === 'COMPLETED' ? 'Watch Review' : 'In Progress' }
                </Button>
                <OrderSnapshot order={order}/>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}