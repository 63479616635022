import React, { useContext, useEffect, useState } from "react";
import { Box, Card } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { authContext } from "contexts/AuthContext";
import ErrorPage from "pages/error/ErrorPage";
import { checkoutOrder } from "helpers/apiHelpers";
import LoadingSpinner from "components/LoadingSpinner";
import OrderSnapshot from "components/OrderSnapshot";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "components/CheckoutForm";


export default function CheckoutPage() {
  const { user, authenticating } = useContext(authContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState();

  const [stripe, setStripe] = useState();

  useEffect(() => {
    if (user?.authenticated && location && searchParams) {
      const orderId = searchParams.get('orderId');
      Promise.all([
        loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY).then(setStripe),
        checkoutOrder(orderId).then(setOrder)
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [user, authenticating, location, searchParams]);

  if (loading) {
    return <LoadingSpinner text="Checking out..." />;
  }

  if (!user?.authenticated && !authenticating) {
    return <ErrorPage errorStatus={401}/>
  }

  return (
    <Box sx={{mb: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <Card elevation={3} sx={{width: '90%', maxWidth: '720px', p: '16px 0', mt: '32px'}}>
        <OrderSnapshot order={order} title="Order Summary"/>
      </Card>
      <Box sx={{mt: '32px'}}>
        {order.paymentSecret && stripe &&
          <Elements options={{clientSecret: order.paymentSecret}} stripe={stripe}>
            <CheckoutForm orderId={order.id} />
          </Elements>
        }
      </Box>
    </Box>
  );
}