import React, { createContext, useEffect, useState } from "react";

import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import LoginModal from "components/LoginModal";
import { loadCoach } from "helpers/apiHelpers";

export const authContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [authenticating, setAuthenticating] = useState(true);
  const [user, setUser] = useState({});
  const [coach, setCoach] = useState({});

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        const userInfo = user.providerData[0];
        user.getIdTokenResult().then((idToken) => {
          const username = userInfo.providerId === 'password' ? user.email : user.phoneNumber;
          const isCoach = !!idToken.claims.isCoach;
          const isAdmin = !!idToken.claims.isAdmin;
          setUser({id: user.uid, authenticated: true, username, isCoach, isAdmin });
          if (isCoach) {
            loadCoach(user.uid).then((coach) => {
              setAuthenticating(false);
              setCoach(coach);
            });
          } else {
            setAuthenticating(false);
          }
        })
      } else {
        setAuthenticating(false);
        setUser({});
      }
    });
  }, []);

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  const closeLoginModal = (evt, reason) => {
    setAuthenticating(false);
    setLoginModalOpen(false);
  };

  const reloadCoach = (coachId) => {
    loadCoach(coachId).then((coach) => {
      setCoach(coach);
    });
  };

  return (
    <authContext.Provider value={{user: user || {}, coach, authenticating, setAuthenticating, openLoginModal, reloadCoach}}>
      {children}
      <LoginModal open={loginModalOpen} handleClose={closeLoginModal} />
    </authContext.Provider>
  );
};
