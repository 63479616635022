import { useContext } from "react";
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth } from '../firebase';
import { authContext } from "contexts/AuthContext";
import { useSearchParams } from "react-router-dom";

/* 
  This React component monitors location.href and logs a user in if the url is an email login link
*/
export default function EmailLoginLinkHandler() {
  const { user, setAuthenticating } = useContext(authContext);
  const [searchParams, setSearchParams] = useSearchParams();

  if (isSignInWithEmailLink(auth, window.location.href) && !user?.authenticated) {
    setAuthenticating(true);
    let email = window.localStorage.getItem('emailForLogin');
    if (!email) {
      /* TODO POST-BETA mg: implement dialog to collect email (when not found localStorage) */
      email = 'foo@example.com';
      throw new Error('Login link must be used on the same device from which it was requested');
    }
    signInWithEmailLink(auth, email).then(() => {
      searchParams.delete('apiKey');
      searchParams.delete('mode');
      searchParams.delete('lang');
      searchParams.delete('oobCode');
      setSearchParams(searchParams);
    }).catch(console.error);
  }
}