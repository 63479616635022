import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Snackbar, SnackbarContent, Typography, styled } from "@mui/material";
import { authContext } from "contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import SendIcon from '@mui/icons-material/Send';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { formatDateTime, formatTime, getElapsedTime } from "helpers/datetimeHelpers";
import { deleteVideo, getReviewVideoUrl, retryFailedProcessing, submitReview, uploadReviewVideo } from "helpers/apiHelpers";
import { Decoder, Reader, tools } from "ts-ebml";

import { Buffer } from 'buffer';
import ProfileSnapshot from "components/ProfileSnapshot";
import ISO6391 from 'iso-639-1';

window.Buffer = Buffer;

const RECORDING_MIME_TYPE = "video/webm";

const LiveRecordingIcon = styled("div")({
  "@keyframes flicker": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.25,
    }
  },
  animation: "flicker alternate 500ms infinite ease-in-out",
});

export default function CoachOrderView({order, refreshOrder}) {
  const { user, coach, reloadCoach } = useContext(authContext);

  const [reviewStatus, setReviewStatus] = useState(order.status);
  const [recordingUrl, setRecordingUrl] = useState();
  const [recordingChunks, setRecordingChunks] = useState([]);
  const [recordingBlob, setRecordingBlob] = useState();
  const [recordingStartTime, setRecordingStartTime] = useState();
  const [recordingRunTime, setRecordingRunTime] = useState();
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [submitConfirmDialogOpen, setSubmitConfirmDialogOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastIsError, setToastIsError] = useState(false);

  useEffect(() => {
    if (recordingChunks?.length) {
      processChunks(recordingChunks).then((seekableBlob) => {
        setRecordingBlob(seekableBlob);
        setRecordingUrl((currentUrl) => {
          if (currentUrl) {
            window.URL.revokeObjectURL(currentUrl);
          }
          return window.URL.createObjectURL(seekableBlob);
        });
      });
    }
  }, [recordingChunks]);

  useEffect(() => {
    setReviewStatus(order.status);
  }, [order]);

  useEffect(() => {
    if (recordingStartTime) {
      const poller = setInterval(() => {
        const runTimeInSeconds = (Date.now() - recordingStartTime) / 1000;
        setRecordingRunTime(formatTime(runTimeInSeconds));
      }, 1000);
      return () => clearInterval(poller);
    }
  }, [recordingStartTime]);

  const processChunks = async (chunks) => {
    const decoder = new Decoder();
    const reader = new Reader();
    reader.logging = false;
    reader.drop_default_duration = false;
    const recordingBlob = new Blob(chunks, {type: RECORDING_MIME_TYPE});
    const buf = await recordingBlob.arrayBuffer();
    const elms = decoder.decode(buf);
    elms.forEach((elm)=>{ reader.read(elm); });
    reader.stop();
    const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
    const body = buf.slice(reader.metadataSize);
    const refinedBlob = new Blob([refinedMetadataBuf, body], {type: RECORDING_MIME_TYPE});
    return refinedBlob;
  };

  let mediaRecorder;

  const screenCaptureOptions = {
    audio: false,
    preferCurrentTab: false,
    selfBrowserSurface: 'exclude',
    surfaceSwitching: 'exclude',
  };

  if (!user?.authenticated || !coach) {
    return;
  }

  const openToast = (message, isError) => {
    setToastMessage(message);
    setToastIsError(!!isError);
    setToastOpen(true);
  };

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const startRecording = async () => {
    try {
      // get mic
      const micStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false})
        .catch((err) => {
          throw new Error('MIC_ERROR');
        });

      // get screen share
      const screenShareStream = await navigator.mediaDevices.getDisplayMedia(screenCaptureOptions)
        .catch((err) => {
          micStream.getAudioTracks()[0].stop();
          throw new Error('SCREEN_CAPTURE_ERROR');
        });
      screenShareStream.getVideoTracks()[0].onended = () => {
        stopRecording();
        micStream.getAudioTracks()[0].stop();
      };

      // record
      const mediaStream = new MediaStream([ screenShareStream.getVideoTracks()[0], micStream.getAudioTracks()[0] ]);
      mediaRecorder = new MediaRecorder(mediaStream, { mimeType: RECORDING_MIME_TYPE });
      mediaRecorder.ondataavailable = (e) => {
        setRecordingChunks((chunks) => [...chunks, e.data]);
      };
      mediaRecorder.start(500);

      setRecordingStartTime(Date.now());
      setRecordingRunTime(formatTime(0));
      setReviewStatus('RECORDING');
    } catch (err) {
      if (err.message === 'MIC_ERROR') {
        openToast('Missing microphone.  Please connect a microphone, and try again.', true);
      } else if (err.message === 'SCREEN_CAPTURE_ERROR') {
        openToast('Error with screen capture.  Please try again.', true);
      } else {
        openToast('Unexpected error recording review, please try again.', true);
      }
      console.error(err);
    }
  };

  const stopRecording = () => {
    mediaRecorder.stop();
    setRecordingStartTime(null);
    setReviewStatus('RECORDED');
  };

  const openDeleteConfirmDialog = () => {
    setDeleteConfirmDialogOpen(true);
  };

  const closeDeleteConfirmDialog = (evt) => {
    setDeleteConfirmDialogOpen(false);
    if (evt?.target?.id === 'delete-confirm-yes') {
      deleteRecording();
    }
  };

  const getDeleteConfirmDialog = () => {
    return (
      <Dialog open={deleteConfirmDialogOpen} onClose={closeDeleteConfirmDialog} aria-labelledby="delete-confirm-dialog-title" aria-describedby="delete-confirm-dialog-description">
        <DialogTitle id="delete-confirm-dialog-title">
          {"Delete current recording?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirm-dialog-description">
            This will permanently delete this recording.  Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="delete-confirm-yes" onClick={closeDeleteConfirmDialog}>YES</Button>
          <Button id="delete-confirm-no" onClick={closeDeleteConfirmDialog} autoFocus>NO</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const openSubmitConfirmDialog = () => {
    setSubmitConfirmDialogOpen(true);
  };

  const closeSubmitConfirmDialog = (evt) => {
    setSubmitConfirmDialogOpen(false);
    if (evt?.target?.id === 'submit-confirm-yes') {
      submitOrder();
    }
  };

  const getSubmitConfirmDialog = () => {
    return (
      <Dialog open={submitConfirmDialogOpen} onClose={closeSubmitConfirmDialog} aria-labelledby="submit-confirm-dialog-title" aria-describedby="submit-confirm-dialog-description">
        <DialogTitle id="submit-confirm-dialog-title">
          {"Submit review?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="submit-confirm-dialog-description">
            This will submit the current review and mark the order as completed.  Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="submit-confirm-yes" onClick={closeSubmitConfirmDialog}>YES</Button>
          <Button id="submit-confirm-no" onClick={closeSubmitConfirmDialog} autoFocus>NO</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const deleteRecording = () => {
    if (reviewStatus === 'READY_TO_SUBMIT') {
      setReviewStatus('DELETING');
      deleteVideo(order.id).then(() => {
        setTimeout(() => refreshOrder(), 5000);
      });
    } else {
      setReviewStatus('IN_QUEUE');
    }
    setRecordingChunks([]);
    if (recordingUrl) {
      window.URL.revokeObjectURL(recordingUrl);
    }
    setRecordingUrl(null);
  };

  const onUpload = () => {
    setReviewStatus('UPLOADING');
    uploadReviewVideo(order.id, recordingBlob).then(() => {
      setReviewStatus('PROCESSING');
      setTimeout(() => refreshOrder(), 10000);
    });
  };

  const submitOrder = () => {
    setReviewStatus('SUBMITTING');
    submitReview(order.id).then(() => {
      setTimeout(() => {
        refreshOrder();
        reloadCoach(user.id);
      }, 5000);
    });
  }

  const retryProcessing = () => {
    setReviewStatus('PROCESSING');
    retryFailedProcessing(order.id).then(() => {
      refreshOrder();
    });
  };

  const renderCtaButton = () => {
    const btnSx = {mt: '16px'};
    switch (reviewStatus) {
      case 'IN_QUEUE':
        return <Button sx={btnSx} onClick={startRecording} size="large" variant="contained" startIcon={<VideoCameraBackIcon />}>Start Recording</Button>;
      case 'RECORDING':
      case 'PROCESSING':
      case 'UPLOADING':
        return <Button sx={btnSx} disabled size="large" variant="contained" startIcon={<SendIcon />}>Upload Video</Button>;
      case 'PROCESSING_ERROR': 
        return <Button sx={btnSx} color="error" onClick={retryProcessing} size="large" variant="contained" startIcon={<SendIcon />}>Retry Processing</Button>;
      case 'RECORDED':
        return <Button sx={btnSx} onClick={onUpload} size="large" variant="contained" startIcon={<SendIcon />}>Upload Video</Button>;
      case 'READY_TO_SUBMIT':
        return <Button sx={btnSx} onClick={openSubmitConfirmDialog} size="large" variant="contained" startIcon={<SendIcon />}>Submit Review</Button>;
      case 'SUBMITTING':
      case 'DELETING':
        return <Button sx={btnSx} disabled size="large" variant="contained" startIcon={<SendIcon />}>Submit Review</Button>;
      default:
        return;
    }
  };

  const renderDeleteVideoButton = () => {
    switch (reviewStatus) {
      case 'RECORDED':
      case 'READY_TO_SUBMIT':
        return <Typography variant="caption" sx={{textDecoration: "underline", color: "#128080", cursor: "pointer", mt: "16px"}} component="span" onClick={openDeleteConfirmDialog}>
          or delete and re-record
        </Typography>
      default:
        return;
    }
  };

  return (
    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", pt: "16px", pb: "72px"}}>
      <Card elevation={3} sx={{width: '90%', maxWidth: '640px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', py: '24px', mb: '16px'}}>
        <Typography variant="h6">Status: {reviewStatus}</Typography>
        { reviewStatus === 'COMPLETED'
          ? <Typography>Wait time: <strong>{getElapsedTime(order.orderDate, order.completedDate)}</strong></Typography> 
          : <Typography>Wait time: <strong>{getElapsedTime(order.orderDate)}</strong></Typography>
        }
        { coach.secondLanguage && 
          <Typography>Language: {ISO6391.getNativeName(order.language)}</Typography>
        }
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', my: '16px'}}>
          <ProfileSnapshot profileData={order} nameFirst />
        </Box>
        <Typography variant="body2" sx={{mt: '4px'}}>Order Price: ${order.price.toFixed(2)}</Typography>
        <Typography variant="body2">Order Date: {formatDateTime(order.orderDate)}</Typography>
        <Typography variant="body2">Order ID: {order.id}</Typography>
        {reviewStatus === 'COMPLETED' ? 
          <Typography variant="body2">Completed: {formatDateTime(order.completedDate)}</Typography>
          :
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            { renderCtaButton() }
            { renderDeleteVideoButton() }
          </Box>
        }
      </Card>

      { (reviewStatus === 'RECORDING' || reviewStatus === 'RECORDED') && 
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mb: '16px'}}>
          { reviewStatus === 'RECORDING' && <LiveRecordingIcon><RadioButtonCheckedIcon color="error" /></LiveRecordingIcon>}
          <Typography variant="h3" sx={{width: '128px', ml: '8px'}}>{recordingRunTime}</Typography>
        </Box>
      }

      { reviewStatus === 'RECORDED' && 
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <video controls src={recordingUrl} style={{maxWidth: '640px'}} />
        </Box>
      }

      { reviewStatus === 'UPLOADING' &&
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <Typography>Uploading your video...</Typography>
          <Typography variant="body2">Do <Typography component="span" sx={{textDecoration: 'underline'}}>NOT</Typography> go back or refresh</Typography>
          <CircularProgress color="primary" size={120} sx={{mt: '32px'}} />
        </Box>
      }

      { reviewStatus === 'PROCESSING' &&
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <Typography>Processing your review video...</Typography>
          <Typography variant="body2">(this may take a few minutes)</Typography>
          <CircularProgress color="primary" size={120} sx={{mt: '32px'}} />
        </Box>
      }

      { reviewStatus === 'SUBMITTING' &&
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <Typography>Submitting your final review...</Typography>
          <CircularProgress color="primary" size={120} sx={{mt: '32px'}} />
        </Box>
      }

      { reviewStatus === 'DELETING' &&
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <Typography>Deleting video...</Typography>
          <CircularProgress color="primary" size={120} sx={{mt: '32px'}} />
        </Box>
      }

      { (reviewStatus === 'COMPLETED' || reviewStatus === 'READY_TO_SUBMIT') &&
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <video controls src={getReviewVideoUrl(order.id)} style={{maxWidth: '640px'}} />
        </Box>
      }
      
      { getDeleteConfirmDialog() }
      { getSubmitConfirmDialog() }

      <Snackbar 
        open={toastOpen}
        onClose={closeToast} 
        autoHideDuration={3000}
        TransitionComponent={Slide}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <SnackbarContent message={toastMessage} sx={{backgroundColor: toastIsError ? "#D32E2E" : "#128080"}}/>
      </Snackbar>
    </Box>
  );
}