import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatDate, formatTime, getElapsedTime } from "helpers/datetimeHelpers";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function CoachOrderList({ orders }) {
  const navigate = useNavigate();

  const rowCss = {
    '&:last-child td, &:last-child th': {border: 0},
    '&:hover': {cursor: 'pointer', backgroundColor: 'rgba(18, 128, 128, 0.10)'},
  };

  return (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', p: "16px"}}>
      <TableContainer component={Paper} sx={{maxHeight: 'calc(100vh - 184px)'}}>
        <Table stickyHeader sx={{minWidth: '480px'}}>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{color: '#128080'}}>Complete Date</TableCell>
              <TableCell align="center" sx={{color: '#128080'}}>Player</TableCell>
              <TableCell align="center" sx={{color: '#128080'}}>Platform</TableCell>
              <TableCell align="right" sx={{color: '#128080'}}>Rapid</TableCell>
              <TableCell align="right" sx={{color: '#128080'}}>Blitz</TableCell>
              <TableCell align="right" sx={{color: '#128080'}}>Wait Time</TableCell>
              <TableCell align="center" sx={{color: '#128080'}}>Video Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { orders.map((order) => (
            <TableRow key={order.id} sx={rowCss} onClick={() => navigate(`/orders/${order.id}`)}>
              <TableCell align="center">{order.status === "COMPLETED" ? formatDate(order.completedDate) : "-"}</TableCell>
              <TableCell align="center">{order.platformUsername}</TableCell>
              <TableCell align="center">{order.platform === "chesscom" ? "chess.com" : "lichess.org"}</TableCell>
              <TableCell align="right">{order.platformProfile.eloRapid}</TableCell>
              <TableCell align="right">{order.platformProfile.eloBlitz}</TableCell>
              <TableCell align="right">{order.status === 'COMPLETED' ? getElapsedTime(order.orderDate, order.completedDate) : getElapsedTime(order.orderDate)}</TableCell>
              <TableCell align="center">{order.status === 'COMPLETED' ? (formatTime(order.videoDuration) || 'x') : '-'}</TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}