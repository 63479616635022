import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();

  const FooterLink = ({text, url, existingTab}) => {
    url = url || `/${text.toLowerCase()}`;
    const disabled = location.pathname === url;
    const link = disabled ? text : <Link to={url} style={{color: "#128080"}}>{text}</Link>
    return (
      <Typography variant="caption" sx={{color: "#128080"}}>{link}</Typography>
    );
  };

  return (
    <Box sx={{display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center', m: '32px 0'}}>
      <Typography variant="caption">&copy; 2024 Check My Chess LLC </Typography>
      <Box sx={{display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', mt: '8px'}}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <FooterLink text="FAQ" />
          <FooterLink text="Coaching" />
          <FooterLink text="Terms" />
          <FooterLink text="Privacy" />
        </Stack>
      </Box>
    </Box>
  );
}