import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import './App.css';
import Header from './components/Header';
import ErrorPage from 'pages/error/ErrorPage';
import CoachPublicPage from 'pages/order-create/CoachPublicPage';
import { AuthProvider } from 'contexts/AuthContext';
import HomePage from 'pages/home/HomePage';
import OrderView from 'pages/order-view/OrderView';
import OrderList from 'pages/order-list/OrderList';
import CheckoutPage from 'pages/order-create/CheckoutPage';
import CoachApplicationPage from 'pages/coach-apply/CoachApplicationPage';
import CoachLandingPage from 'pages/coach-landing/CoachLandingPage';
import EmailLoginLinkHandler from 'components/EmailLoginLinkHandler';
import CoachPaymentsPage from 'pages/coach-payments/CoachPaymentsPage';
import ScrollToTop from 'components/ScrollToTop';
import Footer from 'components/Footer';
import TermsOfServicePage from 'pages/general/TermsOfServicePage';
import PrivacyPolicyPage from 'pages/general/PrivacyPolicyPage';
import FaqPage from 'pages/general/FaqPage';
import { Box } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#128080'
    }
  },
  typography: {
    fontFamily: "Monteserrat"
  }
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <>
      <EmailLoginLinkHandler />
      <ScrollToTop />
      <Header/>
      <Box sx={{minHeight: 'calc(100vh - 184px)'}}>
        <Outlet/>
      </Box>
      <Footer/>
    </>,
    errorElement: <>
      <Header />
      <ErrorPage />
    </>,
    children: [
      {
        path: 'faq',
        element: <FaqPage />
      },
      {
        path: 'terms',
        element: <TermsOfServicePage />
      },
      {
        path: 'privacy',
        element: <PrivacyPolicyPage />
      },
      {
        path: 'coaching',
        element: <CoachLandingPage />
      },
      {
        path: 'coaching/application',
        element: <CoachApplicationPage />
      }, 
      {
        path: 'coaches/:slug',
        element: <CoachPublicPage />
      }, 
      {
        path: 'orders',
        element: <OrderList />
      }, 
      {
        path: 'checkout',
        element: <CheckoutPage />
      }, 
      {
        path: 'orders/:id',
        element: <OrderView />
      }, 
      {
        path: 'payments',
        element: <CoachPaymentsPage />
      }, 
      {
        path: '',
        element: <HomePage />
      }
    ]
  }
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
