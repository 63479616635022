import { Box, IconButton, Slide, Slider, Snackbar, SnackbarContent, Typography } from "@mui/material";
import { authContext } from "contexts/AuthContext";
import ErrorPage from "pages/error/ErrorPage";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseIcon from '@mui/icons-material/Close';
import HttpIcon from '@mui/icons-material/Http';

import CoachReviewQueue from "../../components/CoachReviewQueue";
import LoadingSpinner from "components/LoadingSpinner";
import { updateCoachPrice } from "helpers/apiHelpers";

export default function CoachDashboard() {
  const { user, coach, authenticating, reloadCoach } = useContext(authContext);
  const navigate = useNavigate();

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [editingPrice, setEditingPrice] = useState(false);
  const [price, setPrice] = useState(coach?.price);

  const pollerIntervalInMs = 5 * 60 * 1000;
  
  useEffect(() => {
    const poller = setInterval(() => {
      reloadCoach(user.id);
    }, pollerIntervalInMs);
    return () => clearInterval(poller);
  });

  const getCoachPageLink = () => {
    if (!user?.authenticated || !user.isCoach || !coach) {
      return;
    }
    const path = `/coaches/${coach.slug}`;
    const fullUrl = process.env.REACT_APP_HOST + path;
    
    return (
      <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}} >
        <Box title={fullUrl} sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}} >
          <Typography variant="h5">Page:&nbsp;</Typography>
          <>
            <HttpIcon sx={{fontSize: "48px"}} />
            <Typography variant="body1"> . . . </Typography>
          </>
          <Typography variant="h5">/{coach.slug}</Typography>
        </Box>
        
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", ml: "24px"}} >
          <IconButton aria-label="view-coach-page" title="View coach page" onClick={() => navigate(path)}>
            <PageviewOutlinedIcon />
          </IconButton>
          <IconButton aria-label="copy-coach-page-link" title="Copy coach page link" onClick={() => handleCopyLink(fullUrl)}>
            <ContentCopyIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const handleCopyLink = (url) => {
    copyToClipboard(url)
      .then(() => {
        openToast('Link copied!')
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const openToast = (message) => {
    setToastMessage(message);
    setToastOpen(true);
  }

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const handlePriceChange = (evt, val) => {
    setPrice(val);
  };

  const handleSavePrice = () => {
    updateCoachPrice(user.id, price).then(() => {
      reloadCoach(user.id);
      setEditingPrice(false);
      openToast('Price saved!');
    });
  };

  const handleCancelPrice = () => {
    setEditingPrice(false);
    setPrice(coach.price);
  };

  if (authenticating) {
    return <LoadingSpinner />
  }

  if (!user?.authenticated || !user.isCoach) {
    return <ErrorPage errorStatus={401} />
  }

  return (
    <Box sx={{p: "36px 16px"}}>
      {getCoachPageLink()}

      <Box sx={{mt: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <Typography variant={"h5"} component="span">Price: ${price.toFixed(2)}</Typography>
        { editingPrice ? 
          <Box sx={{display: "flex", flexDirection: "row", ml: "24px"}} >
            <IconButton aria-label="save-price" title="Save price" onClick={handleSavePrice}><SaveOutlinedIcon /></IconButton>
            <IconButton aria-label="cancel-price-change" title="Cancel price change" onClick={handleCancelPrice}><CloseIcon /></IconButton>
          </Box>
          :
          <Box sx={{ml: "24px"}} >
            <IconButton aria-label="edit-price" title="Edit price" onClick={() => setEditingPrice(true)}><EditOutlinedIcon /></IconButton>
          </Box>
        }
      </Box>

      { editingPrice &&
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Slider
            sx={{width: "240px"}}
            aria-label="price"
            value={price}
            min={10}
            max={30}
            onChange={handlePriceChange}
          />
        </Box>
      }

      <CoachReviewQueue coach={coach} />

      <Snackbar 
        open={toastOpen}
        onClose={closeToast} 
        autoHideDuration={3000} 
        TransitionComponent={Slide}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <SnackbarContent message={toastMessage} sx={{backgroundColor: "#128080"}}/>
      </Snackbar>
    </Box>
  );
};