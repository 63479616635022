import { Avatar, AvatarGroup, Badge, Box, Button, Card, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import LoadingSpinner from "components/LoadingSpinner";
import { authContext } from "contexts/AuthContext";
import CoachDashboard from "pages/home/CoachDashboard";
import React, { useContext } from "react";
import AdminDashboard from "./AdminDashboard";

export default function HomePage() {
  const { user, authenticating, openLoginModal } = useContext(authContext);

  if (authenticating) {
    return <LoadingSpinner />
  }

  if (user?.authenticated && user.isCoach) {
    return <CoachDashboard />;
  }
  if (user?.authenticated && user.isAdmin) {
    return <AdminDashboard />;
  }

  const Step = (props) => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <Avatar sx={{bgcolor: '#128080'}} variant="square">{props.num}</Avatar>
          <Typography variant="h6" color="primary" sx={{ml: '8px'}}>{props.title}</Typography>
        </Box>
        <Typography variant="body1" sx={{mt: '16px', maxWidth: '360px', textAlign: 'center'}}>{props.description}</Typography>
      </Box>
    );
  };

  const getCoachAvatar = (num, title) => {
    return (
      <Badge overlap="circular" anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} 
        badgeContent={
          <Avatar sx={{width: 20, height: 20, bgcolor: 'white', mr: '16px'}}>
            <Typography sx={{fontSize: '12px'}} color="primary">{title}</Typography>
          </Avatar>}>
        <Avatar src={`/sample-coach-${num}.jpg`} alt={`sample coach #${num} image`} sx={{ width: 96, height: 96 }}/>
      </Badge>
    );
  };

  return (
    <Box sx={{p: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

      {/* Title */}
      <Typography color="primary" variant="h5" sx={{mb: '16px', textAlign: 'center', maxWidth: '512px'}}>
        Your online chess games reviewed by professional coaches!
      </Typography>

      {/* Step 1 - Find a coach */}
      <Card sx={{my: '16px', p: '48px 32px', width: '80%', maxWidth: '720px'}} elevation={4}>
        <Grid2 container spacing={3}>
          <Grid2 xs={12} md={6}>
            <Step num="1" title="Pick a coach *" description="Search our list of world-class coaches or use a direct link to a coach you already know."/>
          </Grid2>
          <Grid2 xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <AvatarGroup spacing={32}>
              { getCoachAvatar(1, "GM") }
              { getCoachAvatar(2, "IM") }
              { getCoachAvatar(3, "FM") }
            </AvatarGroup>
          </Grid2>
        </Grid2>
      </Card>

      {/* Step 2 - Tell us your username */}
      <Card sx={{my: '16px', p: '48px 32px', width: '80%', maxWidth: '720px'}} elevation={4}>
        <Grid2 container spacing={3}>
          <Grid2 xs={12} md={6}>
            <Step num="2" title="Tell us your username" description="Provide your chess.com or lichess.org username."/>
          </Grid2>
          <Grid2 xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src="/logo-platforms.png" alt="username input field overlapping chess.com and lichess.org logos" style={{maxWidth: '280px'}} />
          </Grid2>
        </Grid2>
      </Card>

      {/* Step 3 - Enjoy your video */}
      <Card sx={{my: '16px', p: '48px 32px', width: '80%', maxWidth: '720px'}} elevation={4}>
        <Grid2 container spacing={3}>
          <Grid2 xs={12} md={6}>
            <Step num="3" title="Enjoy your video" description="Enjoy the video of your coach reviewing key moments from games in your recent online history and detailing the most important areas of improvement."/>
          </Grid2>
          <Grid2 xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src="/sample-review-video.png" alt="example review video in video player" style={{maxWidth: '280px'}} />
          </Grid2>
        </Grid2>
      </Card>

      {/* Early Access Program */}
      <Typography variant="body2" sx={{mt: '32px', maxWidth: '640px', textAlign: 'justify'}}>
        * Our platform is currently in early access. While in early access, the platform does not provide the ability to search for a coach and orders can only be placed using a coach's direct link.
      </Typography>
      { user.authenticated ? 
        <Typography variant="body2" sx={{mt: '8px', maxWidth: '640px', textAlign: 'justify'}}>
          If you don't already have a coach's direct link, we are actively selecting players for our early access program.  If you're selected, we'll email you a direct link to place your order with a coach.
        </Typography>
        :
        <Typography variant="body2" sx={{mt: '8px', maxWidth: '640px', textAlign: 'center'}}>
          If you don't already have a coach's direct link, <Button variant="outlined" size="small" onClick={openLoginModal} sx={{p: 0, pt: '2px'}}>login</Button> and, if you're selected for our early access program, we'll email you one.
        </Typography>
      }
    </Box>
  );
};