import React, { useState } from "react";
import { Box, Button, FormControl, IconButton, Modal, Slide, Snackbar, SnackbarContent, TextField, Typography } from "@mui/material";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as LogoWithText } from '../assets/logo/logo.svg';
import { sendSignInLinkToEmail } from "firebase/auth";
import { auth } from "../firebase";

export default function LoginModal({open, handleClose}) {
  const [email, setEmail] = useState('');
  const [emailLinkSent, setEmailLinkSent] = useState(false);
  const [loginToastOpen, setLoginToastOpen] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    maxWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: '8px 24px',
    maxHeight: '300px'
  };

  const handleEmailChange = ({target: {value}}) => {
    setEmail(value);
    if (value) {
      setEmailError(false);
    }
  };

  const sendEmailLink = () => {
    sendSignInLinkToEmail(auth, email, {
      url: window.location.href,
      handleCodeInApp: true
    }).then(() => {
      window.localStorage.setItem('emailForLogin', email);
      setEmailLinkSent(true);
    }).catch((err) => {
      if (err.message?.indexOf('auth/invalid-email') >= 0) {
        setEmailError(true);
      }
    });
  };

  const validateEmail = () => {
    const validEmailRegex = /^\S+@\S+\.\S+$/;
    const isValid = !!email?.trim().match(validEmailRegex);
    setEmailError(!isValid);
    return isValid;
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const validEmail = validateEmail();
    if (validEmail) {
      sendEmailLink();
    }
  };

  const onLoginCLose = (evt, reason) => {
    if (reason !== 'backdropClick') {
      handleClose?.();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onLoginCLose}
        aria-labelledby="login-modal-title"
        aria-describedby="login-modal-description"
        keepMounted
      >
        <Box sx={style}>
          <IconButton onClick={handleClose} title="Close" size="large" edge="start" color="inherit" aria-label="login" sx={{position: 'absolute', m: '8px', top: 0, right: 0}}>
            <CloseIcon sx={{color: '#808080'}} />
          </IconButton>
          <LogoWithText fill="#128080" style={{maxWidth: "128px"}} />

          { emailLinkSent ?
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: '#acacac', pt: '8px', pb: '22px'}}>
              <Typography variant="body2">An email with a signin link was sent to</Typography>
              <Typography variant="h6" sx={{weight: 'bold'}}>{email}</Typography>
              <Typography variant="body2">click it to login</Typography>
            </Box>
            :
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "100%"}}>
              <form style={{width: '100%'}} onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{mt: '8px'}}>
                  <TextField 
                    label="Your email"
                    InputLabelProps={{ shrink: true }}
                    value={ email || '' } 
                    type="email" 
                    onChange={handleEmailChange} 
                    placeholder="email@example.com"
                    error={emailError}
                    helperText={emailError && '* Invalid email'}
                  />
                </FormControl>
                <button hidden style={{display: 'none'}} type="submit" />
              </form>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', pt: '8px', color: '#acacac'}}>
                <Typography variant="caption">By continuing you agree to CheckMyChess's</Typography>
                <Typography variant="caption">
                  <a href="/terms" target="_blank" style={{color: "#128080"}}>Terms of Service</a> and <a href="/privacy" target="_blank" style={{color: "#128080"}}>Privacy Policy</a>
                </Typography>
              </Box>
              <Button type="submit" variant="contained" sx={{m: "16px 0"}} onClick={handleSubmit} startIcon={<LockOpenIcon />}>Login</Button>
            </Box>
          }
        </Box>
      </Modal>
      <Snackbar 
        open={loginToastOpen} 
        onClose={() => setLoginToastOpen(false)} 
        autoHideDuration={3000} 
        TransitionComponent={Slide}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <SnackbarContent message="Logged in!" sx={{backgroundColor: "#128080"}}/>
      </Snackbar>
    </>
  );
}