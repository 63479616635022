import React, { useContext, useEffect, useState } from "react";
import { authContext } from "contexts/AuthContext";
import CoachOrderList from "./CoachOrderList";
import PlayerOrderList from "./PlayerOrderList";
import LoadingSpinner from "components/LoadingSpinner";
import ErrorPage from "pages/error/ErrorPage";
import { loadOrderHistory } from "helpers/apiHelpers";
import { Box, Typography } from "@mui/material";

export default function OrderList() {
  const { user, authenticating } = useContext(authContext);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!authenticating && user?.authenticated) {
      loadOrderHistory(user).then((orders) => {
        setOrders(orders);
        setLoading(false);
      });
    }
  }, [user, authenticating]);

  if (authenticating) {
    return <LoadingSpinner />;
  } else {
    if (user?.authenticated) {
      if (loading) {
        return <LoadingSpinner />;
      }
      if (orders) {
        return user?.isCoach ? <CoachOrderList orders={orders}/> : <PlayerOrderList orders={orders}/>;
      } else {
        return (
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: "calc(70vh)"}}>
            <Typography variant="h5" sx={{color: '#acacac'}}>No orders yet</Typography>
          </Box>
        );
      }
    }
    return <ErrorPage errorStatus={401} />
  }
}