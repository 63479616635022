import React, { useContext } from "react";
import { Box, Button, List, ListItem, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BenefitPaymentsSvg } from '../../assets/coaching/benefit_payments.svg';
import { ReactComponent as BenefitScheduleSvg } from '../../assets/coaching/benefit_schedule.svg';
import { ReactComponent as BenefitUiSvg } from '../../assets/coaching/benefit_ui.svg';
import { ReactComponent as BenefitRevenueSvg } from '../../assets/coaching/benefit_revenue.svg';
import { ReactComponent as BenefitPublicPageSvg } from '../../assets/coaching/benefit_public_page.svg';
import { ReactComponent as RequirementAgeSvg } from '../../assets/coaching/requirement_age.svg';
import { ReactComponent as RequirementTitledSvg } from '../../assets/coaching/requirement_titled.svg';
import { ReactComponent as RequirementHistorySvg } from '../../assets/coaching/requirement_history.svg';
import { authContext } from "contexts/AuthContext";

export default function CoachLandingPage() {
  const navigate = useNavigate();
  const { user, openLoginModal } = useContext(authContext);

  const handleApply = () => {
    navigate('/coaching/application');
  };

  const benefitSvgStyle = {
    height: '136px',
    width: '136px'
  };

  const StyledText = ({text}) => {
    return <Typography sx={{textAlign: 'center'}} variant="body2">{text}</Typography>;
  };

  const Screenshots = () => {
    return (
      <Grid2 container spacing={3}>
        <Grid2 xs={12} lg={4} lgOffset={2} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <img src="/framed-order-page.png" alt="screenshot of coach order page" style={{width: '100%', maxWidth: '352px'}} />
          <Typography variant="h6" sx={{textAlign: 'center', minWidth: '256px'}}>Get a personalized page where students can order reviews.</Typography>
        </Grid2>
        <Grid2 xs={12} lg={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <img src="/framed-dashboard.png" alt="screenshot of coach dashboard" style={{width: '100%', maxWidth: '512px'}} />
          <Typography variant="h6" sx={{textAlign: 'center', minWidth: '256px'}}>Set your price, pause orders, and more all from a single dashboard.</Typography>
        </Grid2>
        <Grid2 xs={12} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <img src="/framed-submit-review.png" alt="screenshot of review submittal page" style={{width: '100%', maxWidth: '480px'}} />
          <Typography variant="h6" sx={{textAlign: 'center', minWidth: '256px'}}>Record a simple screenshare. No fancy setup necessary.</Typography>
        </Grid2>
      </Grid2>
    );
  };

  const CtaButton = ({my}) => {
    my = my || '64px';
    const onClickHandler = user?.authenticated ? handleApply : openLoginModal;
    const text = user?.authenticated ? 'Apply Now' : 'Login to apply';
    return (
      <Button size="large" sx={{my}} variant="contained" onClick={onClickHandler}>
        {text}
      </Button>
    );
  };

  return (
    <Box sx={{p: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

      <Typography variant="h5" color="primary" sx={{mb: '16px'}}>Become a coach!</Typography>

      <CtaButton my="8px"/>

      <Screenshots />

      <CtaButton />

      <Typography variant="h5" color="primary" sx={{textDecoration: 'underline'}}>Benefits</Typography>
      <Grid2 container spacing={1}>
        <Grid2 xs={6} sm={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <BenefitScheduleSvg style={benefitSvgStyle}/>
          <StyledText text="Perform reviews" />
          <StyledText text="on your own schedule" />
        </Grid2>
        <Grid2 xs={6} sm={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <BenefitPaymentsSvg style={benefitSvgStyle}/>
          <StyledText text="Set your own price," />
          <StyledText text="we handle payments" />
        </Grid2>
        <Grid2 xs={6} sm={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <BenefitRevenueSvg style={benefitSvgStyle}/>
          <StyledText text="Supplement or replace your" />
          <StyledText text="existing coaching business" />
        </Grid2>
        <Grid2 xs={6} sm={4} smOffset={2} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <BenefitUiSvg style={benefitSvgStyle}/>
          <StyledText text="Simple interface" />
          <StyledText text="streamlined for video reviews" />
        </Grid2>
        <Grid2 xs={12} sm={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <BenefitPublicPageSvg style={benefitSvgStyle}/>
          <StyledText text="Get your own order page" />
          <StyledText text="to share with students" />
        </Grid2>
      </Grid2>

      <Typography variant="h5" color="primary" sx={{mt: '64px', textDecoration: 'underline'}}>Requirements</Typography>
      <Grid2 container spacing={1}>
        <Grid2 xs={6} sm={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <RequirementAgeSvg style={benefitSvgStyle}/>
          <StyledText text="Be at least 18" />
          <StyledText text="years of age" />
        </Grid2>
        <Grid2 xs={6} sm={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <RequirementTitledSvg style={benefitSvgStyle}/>
          <StyledText text="Hold a FIDE or" />
          <StyledText text="national title" />
        </Grid2>
        <Grid2 xs={12} sm={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <RequirementHistorySvg style={benefitSvgStyle}/>
          <StyledText text="Have a record" />
          <StyledText text="of quality coaching" />
        </Grid2>
      </Grid2>

      <CtaButton />

      <Typography variant="h5" color="primary" sx={{textDecoration: 'underline'}}>Process</Typography>
      <Typography variant="h6" sx={{mt: '16px'}}>To get a coaching account, you must apply for one by providing the following:</Typography>
      <List sx={{listStyleType: 'disc'}}>
        <ListItem sx={{display: 'list-item'}}><Typography variant="body2">General information (name, date of birth, etc.)</Typography></ListItem>
        <ListItem sx={{display: 'list-item'}}><Typography variant="body2">Contact information (email, phone, etc.)</Typography></ListItem>
        <ListItem sx={{display: 'list-item'}}><Typography variant="body2">Government issued photo ID</Typography></ListItem>
        <ListItem sx={{display: 'list-item'}}><Typography variant="body2">FIDE ID (name must match photo ID)</Typography></ListItem>
      </List>
      <Typography variant="h6" sx={{mt: '16px'}}>To start accepting orders on a coaching account you must:</Typography>
      <List sx={{listStyleType: 'disc'}}>
        <ListItem sx={{display: 'list-item'}}><Typography variant="body2">Set your price per review</Typography></ListItem>
        <ListItem sx={{display: 'list-item'}}><Typography variant="body2">Setup your payments (via 3rd-party payment provider)</Typography></ListItem>
        <ListItem sx={{display: 'list-item'}}><Typography variant="body2">Complete a mock game review to verify your setup</Typography></ListItem>
      </List>

      <Typography variant="body2" sx={{mt: '32px', textAlign: 'center'}}>Have questions?  Email <a href="mailto:info@checkmychess.com">info@checkmychess.com</a></Typography>
    </Box>
  );
}