import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FaqPage() {

  const Faq = (props) => {
    return (
      <Accordion key={props.key} elevation={1}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-conent-faq-${props.key}`} id={`panel-header-faq-${props.key}`}>
          <Typography>{props.q}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {props.a}
        </AccordionDetails>
      </Accordion>
    );
  }

  const Highlight = (props) => {
    return <Typography component="span" color="white" sx={{bgcolor: '#128080'}}>&nbsp;{props.text}&nbsp;</Typography>
  };

  return (
    <Box sx={{p: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <Typography variant="h5" color="primary">Frequently Asked Questions (FAQ)</Typography>
      <Box sx={{maxWidth: '640px', mt: '32px'}}>
        <Faq key="1" 
          q="What's a review?" 
          a={<Typography>A review is <Highlight text="a video"/> of your coach sharing his screen and walking through a selected set of games from your recent online game history.  In the video, your coach will point out the most common mistakes you are making and suggest specifc ways to practice and <Highlight text="to improve your play"/>.</Typography>}
        />
        <Faq key="2" 
          q="How many games will a coach review in a video?" 
          a={<Typography>This can depend on how many games you have recently played and the instructive value the coach finds in their analysis, but each review will typically include <Highlight text="3-5 games"/>.  Keep in mind that the coach may chose to only analyze key moments of certain games to emphasize important concepts and you should not expect the review to include an in depth analysis of any one game.  The coach may also choose to include other top-level games in the video (not played by you) to help illustrate some idea or concept.</Typography>}
        />
        <Faq key="3" 
          q="How long in duration is a review video?" 
          a={<Typography>While ultimately up to the coach's discretion, we encourage coaches to record review videos that are <Highlight text="10-15 minutes"/> in duration.</Typography>}
        />
        <Faq key="4" 
          q="How long will it take to complete my order?" 
          a={<Typography>Most orders are typically completed within <Highlight text="24 hours"/> of receipt but coaches are allotted a maximum of 3 days.</Typography>}
        />
        <Faq key="5" 
          q="For which online chess platforms can I order a review?" 
          a={<Typography>We currently support <Highlight text="chess.com"/> and <Highlight text="lichess.org"/> profiles for review.  Check back as we plan on expanding our supported platforms soon.</Typography>}
        />
        <Faq key="6" 
          q="What if I'm not happy with my review?" 
          a={<Typography>Our mission is to make high quality, affordable chess instruction, easier and more accessible to everyone. If we've missed that mark in any way, and/or you're not 100% satisfied with your order for whatever reason, please contact us at <a href="mailto:info@checkmychess.com" style={{color: '#128080'}}>info@checkmychess.com</a> for a <Highlight text="prompt credit or refund"/>.</Typography>}
        />
      </Box>
    </Box>
  );
}