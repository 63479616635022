import { Box, Button, CircularProgress, Slide, Snackbar, SnackbarContent } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";

export default function CheckoutForm({orderId}) {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastIsError, setToastIsError] = useState(false);

  const openToast = (message, isError) => {
    setToastMessage(message);
    setToastIsError(!!isError);
    setToastOpen(true);
  };

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!stripe || !elements || !orderId) {
      return;
    }
    setSubmitting(true);
    elements.getElement("payment").update({readOnly: true});
    await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_HOST}/orders/${orderId}`
      }
    }).then((result) => {
      if (result.error) {
        openToast(`${result.error.message}`, true);
      }
    }).catch((err) => {
      console.error('unexpected error confirming payment', err);
      openToast('An unexpected error occurred, please try again.');
    });
    setSubmitting(false);
    elements.getElement("payment").update({readOnly: false});
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <PaymentElement id="stripe-payment-element" />
        { submitting ?
          <CircularProgress size={28} color="primary" sx={{mt: '16px'}} />
          :
          <Button onClick={handleSubmit} type="submit" size="large" variant="contained" sx={{mt: '32px'}}>Complete Order</Button>
        }
        <Snackbar 
          open={toastOpen}
          onClose={closeToast} 
          autoHideDuration={5000}
          TransitionComponent={Slide}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
          <SnackbarContent message={toastMessage} sx={{backgroundColor: toastIsError ? "#D32E2E" : "#128080"}}/>
        </Snackbar>
      </Box>
    </form>
  );
};