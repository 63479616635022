import { intervalToDuration, format, secondsToMinutes } from "date-fns";

export const getElapsedTime = (startDate, endDate) => {
  const time = [];
  const duration = intervalToDuration({
    start: new Date(startDate.seconds * 1000),
    end: endDate ? new Date(endDate.seconds * 1000) : new Date()
  });
  if (duration.days) {
    time.push(`${duration.days}d`);
    if (duration.hours) {
      time.push(`${duration.hours}h`)
    }
  } else {
    if (duration.hours) {
      time.push(`${duration.hours}h`)
    } else {
      if (duration.minutes) {
        time.push(`${duration.minutes}m`)
      } else {
        time.push(`${duration.seconds}s`)
      }
    }
  }
  return time.join('');
};

const dateFormat = 'MMMM dd, yyyy';

export const formatDate = ({seconds}) => {
  try {
    const date = new Date(seconds * 1000);
    return format(date, dateFormat);
  } catch (err) {
    return '';
  }
};

const dateTimeFormat = dateFormat + ' h:mm aa';

export const formatDateTime = ({seconds}) => {
  try {
    const date = new Date(seconds * 1000);
    return format(date, dateTimeFormat);
  } catch (err) {
    return '';
  }
};

export const formatTime = (seconds) => {
  if (seconds === null || seconds === undefined || seconds < 0) {
    return;
  }
  seconds = Math.floor(seconds);
  const minutes = secondsToMinutes(seconds);
  seconds = seconds - (minutes * 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};