import React, { useContext } from "react";
import { useRouteError } from "react-router-dom";

import { ReactComponent as NotFoundSvg } from '../../assets/svg/404.svg';
import { ReactComponent as AuthSvg } from '../../assets/svg/Security_Flatline.svg'
import { Box, Button } from "@mui/material";
import { authContext } from "contexts/AuthContext";

export default function ErrorPage(props) {
  const { openLoginModal } = useContext(authContext);
  const error = useRouteError();
  const errorStatus = props?.errorStatus || error?.status;

  const renderError = () => {
    switch (errorStatus) {
      case 404:
        return <NotFoundSvg style={{maxWidth: "100%"}} />;
      case 401:
        return <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <AuthSvg style={{maxWidth: "100%"}} />
          <Button variant="outlined" onClick={openLoginModal}>Login</Button>
        </Box>;
      default:
        return <div>OOPS!</div>;
    }
  }
  
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '75vh'}}>
      {renderError()}
    </Box>
  );
};